<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="detail.distributor_name"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="content">
      <!-- 功能条 -->
      <div class="tool-view">
        <!-- 日期： -->
        <span class="tool-title">日期：</span>
        <a-range-picker
          v-model:value="dateRange"
          :format="dateFormat"
          :allowClear="false"
          @change="onDateChange"
        />
        <!-- 弹簧 -->
        <div style="flex: 1;"></div>
        <!-- 操作 -->
        <a-button @click="touchDownload">导出数据</a-button>
      </div>
      <!-- 列表 -->
      <a-table
        class="table-view"
        :data-source="dataSource"
        :columns="columns"
        :row-key="record => record.id"
        :pagination="pagination"
        :loading="isLoading"
        @change="handleTableChange"
      >
        <!-- 自定义行内容 -->
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'remark_name'">
            {{ record.remark_name }}
          </template>
          <template v-if="column.key === 'order_amount'">
            {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
          </template>
        </template>
      </a-table>
    </div>
  </a-drawer>
</template>

<script setup>
import { message } from 'ant-design-vue'
import { ref, reactive, defineExpose } from 'vue'
import dayjs from 'dayjs'
import Pub from '@/utils/public'
import * as Ex from "@netjoy/excelex"
import { distributorDramaData } from '@/api/copyright'

// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 日期范围
let dateRange = ref([dayjs().subtract(31, 'day'), dayjs().subtract(1, 'day')])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 详情
const detail = ref({})
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '短剧',
    dataIndex: 'remark_name',
    key: 'remark_name'
  },
  {
    title: '充值金额',
    dataIndex: 'order_amount',
    key: 'order_amount'
  }
])

// 打开抽屉
function showDrawer (record) {
  detail.value = record
  visible.value = true
  // 获取剧集列表
  getList()
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    visible.value = false
  }
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 切换日期
function onDateChange () {
  // 设置分页
  pagination.current = 1
  // 数据
  getList()
}

// 获取推广数据列表
function getList () {
  isLoading.value = true
  const params = {
    distributor_id: detail.value.distributor_id,
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  distributorDramaData(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 点击导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    distributor_id: detail.value.distributor_id,
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    page: 1,
    page_size: 10000
  }
  distributorDramaData(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      if (list.length) {
        const columns = [
          {
            name: '短剧',
            field: 'remark_name'
          },
          {
            name: '充值金额',
            field: 'order_amount'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            if (field === 'order_amount') {
              item.data = Pub.KEEP_NUMBER_DECIMAL(item.data / 100, 2, false, true)
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `分销商数据-短剧数据`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch(() => {
    message.error('导出失败')
    isLoading.value = false
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.tool-view {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}
.content-item {
  line-height: 40px;
  .title {
    font-size: 14px;
  }
  .desc {
    font-size: 14px;
    color: #5A5A5A;
  }
}
</style>